export const ZENDESK_KEY = "68e3d8c3-6111-4cb4-8d4e-de3def40787b";
export const SETTINGS = {
  webWidget: {
    contactOptions: {
      enabled: true,
      contactButton: {
        "*": "Get in touch",
      },
    },
    color: {
      theme: "#E81C1C",
      launcher: "#E81C1C",
      launcherText: "#FFFFFF",
      button: "#E81C1C",
      resultLists: "#000000",
      header: "#E81C1C",
      articleLinks: "#E81C1C",
    },
  },
  launcher: {
    chatLabel: {
      "*": "Help",
    },
    mobile: {
      labelVisible: false,
    },
  },
  chat: {
    suppress: true,
    hideWhenOffline: false,
    departments: {
      enabled: ["Professional Services Hospo EN"],
      select: "Professional Services Hospo EN",
    },
  },
  helpCenter: {
    title: {
      "*": "Search for help",
      fr: "Recherche d'aide",
    },
    messageButton: {
      "*": "Get in touch.",
    },
    departments: {
      enabled: ["resto"],
    },
  },
  contactForm: {
    attachments: true,
    tags: ["hospo_pro_services_chat", "nosurvey", "ops_portal"],
    subject: true,
    ticketForms: [
      {
        id: 114093969373,
      },
    ],
    title: {
      "*": "Get in Touch",
    },
    messageButton: {
      "*": "Contact Support",
    },
  },
};
