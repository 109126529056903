// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";
import "firebase/performance";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXYUyN8z0nPsENubE5e-Ofkn2FypIqBt4",
  authDomain: "hospitality-portal.firebaseapp.com",
  databaseURL: "https://hospitality-portal-default-rtdb.firebaseio.com",
  projectId: "hospitality-portal",
  storageBucket: "hospitality-portal.appspot.com",
  messagingSenderId: "58815526650",
  appId: "1:58815526650:web:e8223acf5c857057aabb43",
  measurementId: "G-0EJ0SCY1MD"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.analytics = app.analytics();
    this.performance = app.performance();
  }
  // *** Auth API - Create Account ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  // *** Auth API - EmailAuthProvider ***
  EmailAuthProviderCredential = (email, password) =>
    this.emailAuthProvider.credential(email, password);

  // *** Auth API - Sign In ***
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  // *** AUTH API - Sign In With Google ***
  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  // *** Auth API - UpdateUser
  updateProfile = ({ displayName, phoneNumber }) =>
    this.auth.currentUser.updateProfile({
      displayName: displayName,
      phoneNumber: phoneNumber,
    });

  // *** AUTH API - currentUser
  currentUser = () => this.auth.currentUser;

  // *** Auth API - Sign In ***
  doSignOut = () => this.auth.signOut();

  // *** Auth API - Password Reset ***
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // *** Auth API - Password Update ***
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then((snapshot) => {
            const dbUser = snapshot.val();
            if (!("organisation" in dbUser)) {
              this.user(`${authUser.uid}/organisation`).set(false);
            }
            if (!("lastNps" in dbUser)) {
              let lastNps = dbUser.nps ? new Date().toISOString() : false;
              this.user(`${authUser.uid}/lastNps`).set(lastNps);
            }

            if (!dbUser.roles) {
              dbUser.roles = {};
            }
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              metadata: authUser.metadata,
              displayName: authUser.displayName,
              providerData: authUser.providerData[0],
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API - UID Ref ***
  user = (uid) => this.db.ref(`users/${uid}`);

  // *** User API - all Users ***
  users = () => this.db.ref("users");

  // *** Videos API - all videos ***
  videos = () => this.db.ref("videos");

  // *** Videos API - Single videos ***
  video = (id) => this.db.ref(`videos/${id}`);

  // *** Organization API - all Organizations ***
  organisations = () => this.db.ref("organisations");

  // *** Organization API - Single Organizations ***
  singleOrganisation = (id) => this.db.ref(`organisations/${id}`);

  orgs = () => this.db.ref("orgs");

  // *** Storage API - Reference Documentation ***
  storageRef = () => this.storage.ref();

  // *** Storage API - Reference File ***
  fileRef = (path) => this.storage.ref(path);

  // *** Storage API - Listen for Changes ***
  // storage = () => this.storage

  // *** Monday Board API - Add a New Board to the PubSubQueue ***
  addMondayBoard = (id) => this.db.ref(`Monday/NewBoardQueue/${id}`);

  // *** Monday Board API - Get A Board ***
  getMondayBoard = (boardID) => this.db.ref(`Monday/Boards/${boardID}`);

  // *** NPS API - Single NPS ***
  updateNPS = (npsDate) => this.db.ref(`NPS/${npsDate}`);

  // *** NPS API - all NPS ***
  NPS = () => this.db.ref("NPS");
}

export default Firebase;
