export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const DASHBOARD = '/dashboard';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/forgot-password';
export const VIDEOS = '/videos';
export const DOCUMENTATION = '/documentation';
export const JOURNEY = '/journey'
export const TEST = '/test'
export const CHANGELOG = "/changelog";