import React, {
  Component,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container, Header, Icon, Segment } from "semantic-ui-react";
import PageView from "../CustomHooks/pageview";

// import Navigation from "../Navigation";
// import NPS from "../NPS";
// import LandingPage from "../Home";
// import SignUpPage from "../SignUp";
// import SignInPage from "../SignIn";
// import PasswordForgetPage from "../PasswordForget";
// import DashboardPage from "../Dashboard";
// import AccountPage from "../Account";
// import AdminPage from "../Admin";
// import Videos from "../Videos";
// import Journey from "../Journey";
// import Documentation from "../Documentation";
// import Footer from "../Footer";

//Non Default imports (those that can't be imported lazily)
import Zendesk from "react-zendesk";
import { ZendeskAPI } from "react-zendesk";
import { ZENDESK_KEY, SETTINGS } from "../../constants/zendesk";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext, withAuthentication } from "../Session";

const Navigation = lazy(() => import("../Navigation"));
const NPS = lazy(() => import("../NPS"));
const LandingPage = lazy(() => import("../Home"));
const SignInPage = lazy(() => import("../SignIn"));
const SignUpPage = lazy(() => import("../SignUp"));
const PasswordForgetPage = lazy(() => import("../PasswordForget"));
const DashboardPage = lazy(() => import("../Dashboard"));
const AccountPage = lazy(() => import("../Account"));
const AdminPage = lazy(() => import("../Admin"));
const Videos = lazy(() => import("../Videos"));
const Journey = lazy(() => import("../Journey"));
const Documentation = lazy(() => import("../Documentation"));
const Footer = lazy(() => import("../Footer"));
const Changelog = lazy(()=> import("../ChangeLog"));

const renderLoader = () => {
  return (
    <Container fluid textAlign="center">
      <Segment fluid="true" basic style={{ paddingTop: "20vh" }}>
        <Header as="h1" icon>
          <Icon name="sync" loading />
          Loading
          <Header.Subheader>
            Bringing up <strong>your</strong> customer Portal
          </Header.Subheader>
        </Header>
      </Segment>
    </Container>
  );
};
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dark: false,
    };
  }
  toggleDark = () => {
    const { dark } = this.state;
    this.setState({
      dark: !dark,
    });
  };

  render() {
    const { dark } = this.state;
    const { firebase } = this.props;

    return (
      <Suspense fallback={renderLoader()}>
        <Router>
          <Navigation dark={dark} toggleDark={this.toggleDark}>
            <NPS dark={dark} toggleDark={this.toggleDark} />
            <Switch>
              <Route exact path={ROUTES.HOME}>
                <LandingPage dark={dark} />
              </Route>
              <Route path={ROUTES.DASHBOARD}>
                <DashboardPage dark={dark} />
              </Route>
              <Route path={ROUTES.VIDEOS}>
                <Videos dark={dark} />
              </Route>
              <Route path={ROUTES.DOCUMENTATION}>
                <Documentation dark={dark} />
              </Route>
              <Route path={ROUTES.JOURNEY}>
                <Journey dark={dark} />
              </Route>
              <Route path={ROUTES.ADMIN}>
                <AdminPage dark={dark} />
              </Route>
              <Route path={ROUTES.ACCOUNT}>
                <AccountPage dark={dark} />
              </Route>
              <Route path={ROUTES.SIGN_UP}>
                <SignUpPage dark={dark} />
              </Route>
              <Route path={ROUTES.SIGN_IN}>
                <SignInPage dark={dark} />
              </Route>
              <Route path={ROUTES.PASSWORD_FORGET}>
                <PasswordForgetPage dark={dark} />
              </Route>
              <Route path={ROUTES.CHANGELOG}>
                <Changelog dark={dark} />
              </Route>
              {/* <Route>
                <NoMatch />
                </Route> */}
            </Switch>
            <Footer dark={dark} />
            <AuthUserContext.Consumer>
              {(authUser) => {
                return (
                  <ZendeskComponent firebase={firebase} authUser={authUser} />
                );
              }}
            </AuthUserContext.Consumer>
          </Navigation>
          <AuthUserContext.Consumer>
            {(authUser) => <PageView authUser={authUser} />}
          </AuthUserContext.Consumer>
        </Router>
      </Suspense>
    );
  }
}

const ZendeskComponent = (props) => {
  const [chat, setChat] = useState(true);
  const [renderReady, setRenderReady] = useState(false);
  const { firebase, authUser } = props;
  const prefill = {};
  const getOrg = useCallback(
    (org) => {
      if (org) {
        firebase
          .singleOrganisation(org)
          .once("value")
          .then((snap) => {
            let singleOrg = snap.val();
            setChat(!singleOrg.chat);
            setRenderReady(true);
          });
      } else {
        setRenderReady(true);
      }
    },
    [firebase]
  );
  useEffect(() => {
    if (authUser !== null) {
      if (Object.prototype.hasOwnProperty.call(authUser, "organisation")) {
        getOrg(authUser.organisation);
      } else {
        setRenderReady(true);
      }
    } else {
      setRenderReady(true);
    }
  }, [authUser, getOrg]);
  if (authUser && renderReady) {
    prefill.name = {
      value: `${authUser.displayName}`,
      readOnly: true,
    };
    prefill.email = {
      value: `${authUser.email}`,
      readOnly: true,
    };
    prefill.phone = {
      value: `${authUser.phoneNumber}`,
      readOnly: false,
    };
    SETTINGS.chat.suppress = chat;
    return (
      <Zendesk
        zendeskKey={ZENDESK_KEY}
        {...SETTINGS}
        onLoaded={() => {
          console.log("ZD Widget loaded");
          ZendeskAPI("webWidget", "prefill", prefill);
        }}
      />
    );
  } else {
    return null;
  }
};

export default React.memo(withAuthentication(Routes));
